import { createSlice } from '@reduxjs/toolkit';

export const { reducer } = createSlice({
  name: 'addPhoto',
  initialState: {
    listPhotosBeforeUploaded: [],
    allowedLeaveScreen: false,
    isResetUserPhotos: false,
    screen: 'create_review',
    userPhotos: [],
  },
  reducers: {
    setState(state, { name, value }) {
      state[name] = value;
    },
    setStatusApi(state, { name, value }) {
      state.status[name] = value;
    },
    resetState(state) {
      state.allowedLeaveScreen = false;
      state.isResetUserPhotos = false;
      state.listPhotosBeforeUploaded = [];
      state.userPhotos = [];
    },
  },
});

export default reducer;
