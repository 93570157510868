import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '~api/address';

export const list = createAsyncThunk(
  'address/list',
  async () => {
    const response = await api.getAddress();
    return response.data;
  }
);

export const create = createAsyncThunk(
  'address/create',
  async (payload, { getState }) => {
    const { address } = getState();
    const hasDefaultAddress = Object.keys(address.default).length > 0;
    if (payload.is_default && hasDefaultAddress) {
      await api.updateAddress({
        id: address.default._id,
        payload: { ...address.default, is_default: false },
      });
    }
    return api.createAddress(payload);
  },
);
export const update = createAsyncThunk(
  'address/update',
  async ({ id, payload }, { getState }) => {
    const { address } = getState();
    const hasDefaultAddress = Object.keys(address.default).length > 0;
    if (payload.is_default && hasDefaultAddress) {
      await api.updateAddress({
        id: address.default._id,
        payload: { ...address.default, is_default: false },
      });
    }
    return api.updateAddress({ id, payload });
  },
);
export const remove = createAsyncThunk('address/remove', ({ id }) =>
  api.deleteAddress({ id }),
);
export const setDefault = createAsyncThunk(
  'address/setDefault',
  (payload, { getState }) => {
    const { address } = getState();
    const queue = [];

    // set previous default address to false, if any
    if (Object.keys(address.default).length > 0) {
      queue.push(
        api.updateAddress({
          id: address.default._id,
          payload: { ...address.default, is_default: false },
        }),
      );
    }
    queue.push(
      api.updateAddress({
        id: payload._id,
        payload: { ...payload, is_default: true },
      }),
    );
    return Promise.all(queue);
  },
);

export const getSingleAddress = createAsyncThunk(
  'address/getSingleAddress',
  async (id) => {
    const response = await api.getAddress();
    let editAddress;
    response.data.data[0].address.forEach((item, key) => {
      if (item['_id'] === id) {
        editAddress = item;
      }
    });
    return editAddress;
  }
);
