import { createSlice } from '@reduxjs/toolkit';
import {
  list,
  create,
  update,
  remove,
  setDefault,
  getSingleAddress,
} from '~store/address/thunks';

const status = {
  list: 'idle',
  create: 'idle',
  update: 'idle',
  remove: 'idle',
  setDefault: 'idle',
};
export const { actions, reducer } = createSlice({
  name: 'address',
  initialState: {
    list: [],
    edit: {},
    default: {},
    selected: {},
    status,
  },
  reducers: {
    edit: (state, action) => {
      state.edit = action.payload;
    },
    selected: (state, action) => {
      state.selected = action.payload;
    },
    setLoading: (state, action) => {
      state.list = state.list.map((item, index) =>
        index == action.payload ? { ...item, loading: true } : item,
      );
    },
    setStatus: (state, { payload: { key, value } }) => {
      state.status[key] = value;
    },
    resetState: (state) => {
      state.list = [];
      state.edit = {};
      state.default = {};
      state.selected = {};
    },
    resetStatus: (state) => {
      state.status = status;
    },
  },
  extraReducers: {
    [list.pending]: (state) => {
      state.status.list = 'pending';
    },
    [list.fulfilled]: (state, { payload }) => {
      state.list = payload.data[0].address ?? [];
      if (payload.data[0].address) {
        const defaultAddress = payload.data[0].address.find(
          ({ is_default }) => is_default,
        );
        state.default = defaultAddress || payload.data[0].address[0];
      } else {
        state.default = {};
      }
      if (Object.keys(state.selected).length == 0) {
        state.selected = Object.keys(state.default || {}).length
          ? state.default
          : payload.data[0].address
          ? payload.data[0].address[0]
          : {};
      } else {
        state.selected =
          payload.data[0].address.find(
            ({ _id }) => _id == state.selected._id,
          ) ||
          payload.data[0].address[0] ||
          {};
      }
      state.status.list = 'succeeded';
      state.status.create = 'idle';
      state.status.update = 'idle';
      state.status.remove = 'idle';
      state.status.setDefault = 'idle';
    },
    [create.pending]: (state) => {
      state.status.create = 'pending';
    },
    [create.fulfilled]: (state, { payload }) => {
      const listNewAddress =
        payload?.data?.data && 'address' in payload.data.data
          ? payload.data.data.address
          : null;
      if (listNewAddress) {
        state.list = listNewAddress;
      }
      state.status.list = 'idle';
      state.status.create = 'succeeded';
    },
    [create.rejected]: (state) => {
      state.status.create = 'rejected';
    },
    [update.pending]: (state) => {
      state.status.update = 'pending';
    },
    [update.fulfilled]: (state) => {
      state.status.list = 'idle';
      state.status.update = 'succeeded';
    },
    [update.rejected]: (state) => {
      state.status.update = 'rejected';
    },
    [remove.pending]: (state) => {
      state.status.remove = 'pending';
    },
    [remove.fulfilled]: (state) => {
      state.status.list = 'idle';
      state.status.remove = 'succeeded';
    },
    [setDefault.pending]: (state) => {
      state.status.setDefault = 'pending';
    },
    [setDefault.fulfilled]: (state) => {
      state.status.list = 'idle';
      state.status.setDefault = 'succeeded';
    },
    [getSingleAddress.pending]: (state) => {
      state.status.singleAddress = 'pending';
    },
    [getSingleAddress.fulfilled]: (state, { payload }) => {
      state.status.singleAddress = 'succeeded';
      state.edit = payload;
    },
  },
});

export default reducer;
