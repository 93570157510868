import React, { useState, useEffect, useMemo } from 'react';
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  Pressable,
  Image,
} from 'react-native';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Input, ThemeProvider } from 'react-native-elements-light';
//
import { isWeb } from '~global/screens';
import Colors from '~global/Colors';
import { data as Fonts } from '~global/Fonts';
import { getBrandsLettersRequest } from '~store/brand/brandSlice';
const ActivityIndicator = React.lazy(() =>
  import('@web-components/common/ActivityIndicator'),
);
import { search as searchTheme } from '@web/styles/input';
import { imageCdn } from '~global/helpers';
import { getFeaturedBrandsRequest } from '~store/brand/brandSlice';
import { Link } from '@react-navigation/native';

const listAlphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '#',
];
const defaultImage = require('~assets/img/dummy_product.png');
let flatListGroupBrandRef = null;

const Brands = (props) => {
  const dispatch = useDispatch();
  const {
    brandsLetters,
    brandsFiltered,
    featuredBrands,
    status: { brandsLetters: statusBrandsLetters },
  } = useSelector((state) => state.brand);
  const feautreSlice = useMemo(
    () => featuredBrands.slice(0, 9),
    [featuredBrands],
  );
  const [searchValue, setSearchValue] = useState('');
  const [activeLetter, setActiveLetter] = useState(-1);
  const [filteredByKeyword, setFilteredByKeyword] = useState([]);

  const scrollToIndexHandler = (index) => {
    setActiveLetter(index);
    setTimeout(() => {
      flatListGroupBrandRef.scrollToIndex({ animated: true, index });
    }, 500);
  };

  const getFeaturedBrandsRequestHandler = async () => {
    try {
      await dispatch(getFeaturedBrandsRequest());
    } catch (err) {
      console.log(err);
    }
  };

  const searchHandler = (value) => {
    setSearchValue(value);
    if (!value) {
      setFilteredByKeyword([]);
      return;
    }

    const filteredByKeyword = brandsFiltered.filter((brand) => {
      return brand.name.toLowerCase().includes(value.toLowerCase());
    });

    setFilteredByKeyword(filteredByKeyword);
  };

  const getBrandsLettersRequestHandler = () => {
    try {
      dispatch(getBrandsLettersRequest());
    } catch (err) {
      console.log(err);
    }
  };

  const imageSource = (image, width = 100) => {
    return imageCdn({ config: `w=${width}`, image });
  };

  const navigateHandler = async (brand) => {
    await dispatch({
      type: 'BRAND/SET_STATUS_BRAND_API',
      stateName: 'detailInfo',
      data: 'idle',
    });
    await dispatch({
      type: 'BRAND/RESET_STATE_BRAND_DETAIL',
      data: { tab: 'Home' },
    });

    props.navigation.replace('BrandDetailScreen', {
      slug: brand.slug,
    });
  };

  const renderItemBrand = ({ item, index }) => {
    return brandsLetters[item].length ? (
      <View key={`body${item._id}`}>
        <Text
          style={[
            styles.brandsTextAlphabet,
            index == 0 ? styles.marginTopZero : {},
            activeLetter == index ? styles.brandSelected : {},
          ]}>
          {item}
        </Text>
        {brandsLetters[item].map((brand, key) => (
          <Link
            key={`content${key}`}
            to={`/${brand.slug}`}
            action={() => navigateHandler(brand)}>
            <View style={styles.cursorPointer}>
              <Text style={styles.brandsText}>{brand.name}</Text>
            </View>
          </Link>
        ))}
      </View>
    ) : null;
  };

  const renderItemBrandFiltered = ({ item }) => {
    return (
      <Link
        to={`/${item.slug}`}
        key={`body-${item._id}`}
        action={() => navigateHandler(item)}>
        <View key={`body-${item._id}`} style={styles.cursorPointer}>
          <Text style={styles.brandsText}>{item.name}</Text>
        </View>
      </Link>
    );
  };

  const renderItemFeatured = ({ item }) => {
    return (
      <Link to={`/${item.slug}`} action={() => navigateHandler(item)}>
        <View style={[styles.featuredItem, styles.cursorPointer]}>
          <Image
            source={
              item.logo ? { uri: imageSource(item.logo, 120) } : defaultImage
            }
            style={styles.featuredImage}
            resizeMode="contain"
            defaultSource={defaultImage}
          />
        </View>
      </Link>
    );
  };

  useEffect(() => {
    if (
      !['loading', 'succeeded'].includes(statusBrandsLetters) &&
      brandsLetters.length == 0
    ) {
      getBrandsLettersRequestHandler();
    }

    return () => {
      setFilteredByKeyword([]);
      setSearchValue('');
    };
  }, []);

  useEffect(() => {
    if (
      featuredBrands &&
      Array.isArray(featuredBrands) &&
      featuredBrands.length === 0
    ) {
      getFeaturedBrandsRequestHandler();
    }
  }, [featuredBrands]);

  return (
    <View style={styles.brands} {...props}>
      {statusBrandsLetters === 'loading' && (
        <View style={styles.viewLoading}>
          <ActivityIndicator />
        </View>
      )}

      {statusBrandsLetters === 'succeeded' && (
        <>
          <View style={styles.brandsAlphabet}>
            <ThemeProvider theme={searchTheme}>
              <Input
                placeholder="Search brand..."
                placeholderTextColor={Colors.grey2}
                inputContainerStyle={styles.inputContainerStyle}
                value={searchValue}
                onChangeText={searchHandler}
              />
            </ThemeProvider>
            <View style={styles.alphabetContainer}>
              {listAlphabet.map((item, idx) => {
                return (
                  <Pressable
                    key={'item-' + idx}
                    style={styles.cursorPointer}
                    disabled={Boolean(!brandsLetters[item].length)}
                    onPress={() => {
                      scrollToIndexHandler(idx);
                    }}>
                    <Text
                      style={[
                        styles.alphabet,
                        activeLetter == idx ? styles.alphabetSelected : {},
                        !brandsLetters[item].length
                          ? styles.alphabetDisabled
                          : {},
                      ]}>
                      {item}
                    </Text>
                  </Pressable>
                );
              })}
            </View>
          </View>
          <View style={styles.brandsList}>
            {searchValue ? (
              <FlatList
                removeClippedSubviews={true}
                data={filteredByKeyword}
                renderItem={renderItemBrandFiltered}
                keyExtractor={(item, idx) => item + '-' + idx}
                ListEmptyComponent={
                  <Text style={styles.brandsText}>No brands found</Text>
                }
              />
            ) : (
              <FlatList
                onScrollToIndexFailed={() => {}}
                removeClippedSubviews={true}
                data={listAlphabet}
                renderItem={renderItemBrand}
                keyExtractor={(item) => item}
                ref={(ref) => (flatListGroupBrandRef = ref)}
                initialNumToRender={50}

                // getItemLayout={(data, index) => ({ length: 200, offset: 200 * index, index })}
              />
            )}
          </View>
          <View style={styles.brandsFeature}>
            <Text style={styles.featuredText}>Featured Brands</Text>
            <FlatList
              data={feautreSlice}
              renderItem={renderItemFeatured}
              keyExtractor={(item) => item._id}
              removeClippedSubviews={true}
              showsHorizontalScrollIndicator={false}
              numColumns={3}
            />
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  alphabet: {
    fontSize: 16,
    color: Colors.black,
    paddingHorizontal: 8,
    paddingVertical: 3,
    marginBottom: 12,
    marginRight: 5,
    ...Fonts.satoshiMedium,
  },
  alphabetSelected: {
    color: Colors.white,
    backgroundColor: Colors.purple4,
    ...(isWeb && { borderRadius: '50%' }),
  },
  alphabetContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
  },
  alphabetDisabled: { color: Colors.grey0 },
  brands: {
    position: 'absolute',
    top: 45,
    left: 115,
    height: 331,
    backgroundColor: Colors.white,
    ...(isWeb && { boxShadow: '0 11px 11px 0 rgba(0,0,0,0.16)' }),
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    flexDirection: 'row',
    flex: 1,
  },
  brandsAlphabet: {
    flex: 1.1,
    backgroundColor: Colors.pastel9,
    paddingHorizontal: 24,
    paddingVertical: 30,
  },
  brandsList: {
    flex: 0.9,
    paddingLeft: 24,
    paddingVertical: 30,
    borderRightWidth: 1,
    borderRightColor: Colors.grey10,
  },
  brandsFeature: {
    flex: 2,
    paddingHorizontal: 24,
    paddingTop: 30,
  },
  brandSelected: {
    color: Colors.purple4,
  },
  brandsText: {
    fontSize: 14,
    color: Colors.purple7,
    lineHeight: 28,
    ...Fonts.satoshiMedium,
  },
  brandsTextAlphabet: {
    ...Fonts.satoshiMedium,
    fontSize: 16,
    color: Colors.purple7,
    marginVertical: 12,
  },
  cursorPointer: {
    ...(isWeb && { cursor: 'pointer' }),
  },
  featuredImage: {
    width: 120,
    height: 65,
  },
  featuredItem: {
    paddingLeft: 8,
    paddingRight: 16,
    paddingBottom: 10,
  },
  featuredText: {
    ...Fonts.satoshiMedium,
    fontSize: 16,
    color: Colors.purple7,
    letterSpacing: 0,
    marginBottom: 20,
  },
  inputContainerStyle: {
    ...searchTheme.Input.inputContainerStyle,
    backgroundColor: Colors.white,
    borderColor: Colors.grey2,
    ...(isWeb && { outlineWidth: 0 }),
  },
  marginTopZero: {
    marginTop: 0,
  },
  viewLoading: {
    width: '50vw',
  },
});

Brands.propTypes = {
  navigation: PropTypes.object,
};

Brands.defaultProps = {
  navigation: {},
};

export default Brands;
